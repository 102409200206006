@font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-black.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-black.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-black.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-black.ttf") format("truetype");
    font-weight: black;
    font-style: black;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-bold.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-bold.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-bold.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-bold.ttf") format("truetype");
    font-weight: 700;
    font-style: bold;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-demibold.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-demibold.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-demibold.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-demibold.ttf") format("truetype");
    font-weight: 600;
    font-style: demibold;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-extrabold.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-extrabold.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-extrabold.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-extrabold.ttf") format("truetype");
    font-weight: 800;
    font-style: extrabold;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-extralight.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-extralight.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-extralight.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-extralight.ttf") format("truetype");
    font-weight: 200;
    font-style: extralight;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-light.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-light.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-light.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-light.ttf") format("truetype");
    font-weight: 300;
    font-style: light;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-medium.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-medium.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-medium.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-medium.ttf") format("truetype");
    font-weight: 500;
    font-style: medium;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-regular.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-regular.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-regular.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-regular.ttf") format("truetype");
    font-weight: 400;
    font-style: regular;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-thin.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-thin.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-thin.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-thin.ttf") format("truetype");
    font-weight: thin;
    font-style: thin;
  }
  
  @font-face {
    font-family: "dana";
    src: url("../assets/fonts/dana/eot/dana-fanum-ultrabold.eot"),
      url("../assets/fonts/dana/woff2/dana-fanum-ultrabold.woff2") format("woff2"),
      url("../assets/fonts/dana/woff/dana-fanum-ultrabold.woff") format("woff"),
      url("../assets/fonts/dana/ttf/dana-fanum-ultrabold.ttf") format("truetype");
    font-weight: 900;
    font-style: ultrabold;
  }